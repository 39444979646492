<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <b-button variant="link" class="text-primary refresh" v-b-tooltip.hover.bottom :title="$t('general.refresh')" @click="getClinics">
                <i class="fas fa-sync-alt"></i>
            </b-button>
            <base-button type="success" @click="addNew" class="my--5">
                <i class="fas fa-plus"></i> {{$t('general.addnew')}}</base-button>
        </b-card>
        <b-card no-body v-if="clinics" class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="clinics" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')" :row-class-name="tableRowClassName">
                <el-table-column min-width="110" label="Logo" class-name="clinicLogo">
                    <template v-slot="{row}">
                        <div class="avatarSection" @click="addPhoto(row)">
                            <b-badge variant="success" class="badge-circle badge-md badge-floating border-white">
                                <i class="fas fa-plus"></i>
                            </b-badge>
                            <b-avatar variant="primary" v-if="!row.logo" text="L"></b-avatar>
                            <b-avatar v-else :src="imgUrl+row.logo"></b-avatar>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column :label="$t('general.name')" prop="name" min-width="160">
                </el-table-column>

                <el-table-column :label="$t('general.branch')" prop="branch" min-width="160">
                    <template v-slot="{row}">
                        <small v-html="$options.filters.showBranch(row.branch)"></small>
                    </template>
                </el-table-column>

                <el-table-column :label="$t('general.country')" prop="country" min-width="160">
                    <template v-slot="{row}">
                        {{row.country | showCountry}}
                    </template>
                </el-table-column>

                <el-table-column :label="$t('general.city')" prop="city" min-width="160">
                    <template v-slot="{row}">
                        {{row.city | showCity}}
                    </template>
                </el-table-column>

                <el-table-column :label="$t('general.status')" prop="status" min-width="140">
                    <template v-slot="{row}">
                        <badge class="badge-dot mr-4" type="">
                            <i :class="`bg-${status[row.status]}`"></i>
                            <span class="status" :class="`text-${status[row.status]}`">{{row.status?$t('general.active'):$t('general.passive')}}</span>
                        </badge>
                    </template>
                </el-table-column>

                <el-table-column :label="$t('general.private')" prop="status" min-width="140">
                    <template v-slot="{row}">
                        <badge class="badge-dot mr-4" type="">
                            <i :class="`bg-${status[row.privateClinic]}`"></i>
                            <span class="status" :class="`text-${status[row.privateClinic]}`">{{row.privateClinic?$t('general.active'):$t('general.passive')}}</span>
                        </badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.created_date')" prop="createdAt" min-width="210" sortable>
                    <template v-slot="{row}">
                        {{row.createdAt | getDate}}
                    </template>
                </el-table-column>
                <el-table-column align="right" min-width="180">
                    <template slot-scope="scope">
                        <base-button type="primary" size="sm" @click="handleEdit(scope.row)">{{$t('general.edit')}}</base-button>
                        <base-button type="danger" size="sm" @click="handleDelete(scope.row.uuid)">{{$t('general.delete')}}</base-button>
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
        </b-card>

        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modalClose" size="lg">
            <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
                <h6 slot="header" class="modal-title">{{modal.data ? $t('clinic.edit') : $t('clinic.add')}}</h6>
                <hr>
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)" v-loading="modal.loading">
                    <b-row>
                        <b-col md="4">
                            <label class="form-control-label">{{$t('general.status')}}</label>
                            <b-form-checkbox v-model="modal.data.status" class="switchCheck dblock" name="check-button" switch>
                                {{modal.data.status ? $t('general.active') : $t('general.passive')}}
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="4">
                            <base-input type="text" name="name" :nameAs="$t('clinic.name')" :label="$t('clinic.name')" :placeholder="$t('clinic.name')" @input="generateSeo('name')" v-model="modal.data.name" required>
                            </base-input>
                        </b-col>
                        <b-col md="4">
                            <base-input type="text" name="seo" :nameAs="$t('clinic.seo')" :label="$t('clinic.seo')" :placeholder="$t('clinic.seo')" @input="generateSeo('seo')" v-model="modal.data.seo" required>
                            </base-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="4">
                            <base-input type="text" name="url" :nameAs="$t('clinic.url')" :label="$t('clinic.url')" :placeholder="$t('clinic.url')" v-model="modal.data.url">
                            </base-input>
                        </b-col>
                        <b-col md="4">
                            <base-input type="text" name="phone" :nameAs="$t('general.phone')" :label="$t('general.phone')" :placeholder="$t('general.phone')" v-model="modal.data.phone">
                            </base-input>
                        </b-col>
                        <b-col md="4">
                            <base-input type="email" name="email" :nameAs="$t('general.email')" :label="$t('general.email')" :placeholder="$t('general.email')" v-model="modal.data.email">
                            </base-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="4">
                            <base-input :label="$t('general.country')" name="country" :nameAs="$t('general.country')" :rules="'required'">
                                <el-select v-model="modal.data.country" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required>
                                    <el-option v-for="(item, key) in countries" :key="key" :label="item.name" :value="item._id"></el-option>
                                </el-select>
                            </base-input>
                        </b-col>
                        <b-col md="4">
                            <base-input :label="$t('general.city')" name="city" :nameAs="$t('general.city')" :rules="'required'">
                                <el-select v-model="modal.data.city" filterable :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" :placeholder="$t('general.select')" @change="changeCity" required>
                                    <el-option v-for="(item, key) in cities" :key="key" :label="item.name" :value="item._id"></el-option>
                                </el-select>
                            </base-input>
                        </b-col>
                        <b-col md="4">
                            <base-input type="text" name="address" :nameAs="$t('general.address')" :label="$t('general.address')" :placeholder="$t('general.address')" v-model="modal.data.address">
                            </base-input>
                        </b-col>
                    </b-row>
                    <div class="simple-map">
                        <GmapMap :center.sync="center" :options="mapOptions" map-type-id="terrain" @click="markerDrag">
                            <GmapMarker :position="modal.data.location" :clickable="true" :draggable="true" @dragend="markerDrag" v-if="modal.data.location.lat>0" />
                        </GmapMap>
                    </div>
                    <hr>
                    <b-row class="mb-3">
                        <b-col md="3">
                            <label class="form-control-label">{{$t('general.virtualPos')}}</label>
                            <b-form-checkbox v-model="modal.data.payTr.status" class="switchCheck dblock" name="check-button" switch>
                                {{modal.data.status ? $t('general.active') : $t('general.passive')}}
                            </b-form-checkbox>
                        </b-col>
                        <template v-if="modal.data.payTr.status === true">
                            <b-col md="3">
                                <base-input type="text" name="virtualposId" nameAs="PayTR Id" label="PayTR Id" placeholder="PayTR Id" v-model="modal.data.payTr.id" required>
                                </base-input>
                            </b-col>
                            <b-col md="3">
                                <base-input type="text" name="virtualposKey" nameAs="PayTR Key" label="PayTR Key" placeholder="PayTR Key" v-model="modal.data.payTr.key" required>
                                </base-input>
                            </b-col>
                            <b-col md="3">
                                <base-input type="text" name="virtualposSalt" nameAs="PayTR Salt" label="PayTR Salt" placeholder="PayTR Salt" v-model="modal.data.payTr.salt" required>
                                </base-input>
                            </b-col>
                            <b-col md="6">
                                <base-input type="text" name="virtualposUrl" nameAs="PayTR URL" label="PayTR URL" placeholder="PayTR URL" v-model="modal.data.payTr.url" required>
                                </base-input>
                                <small>{{$t('info.https_info_text', {url: 'transactions.example.com'})}}</small>
                            </b-col>
                        </template>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col md="3">
                            <label class="form-control-label">{{$t('general.panelUrlStatus')}}</label>
                            <b-form-checkbox v-model="modal.data.panelUrlStatus" class="switchCheck dblock" name="check-button" switch>
                                {{modal.data.panelUrlStatus ? $t('general.active') : $t('general.passive')}}
                            </b-form-checkbox>
                        </b-col>
                        <template v-if="modal.data.panelUrlStatus === true">
                            <b-col md="6">
                                <base-input type="text" name="panelUrlStatus" nameAs="Web URL" label="Web URL" placeholder="Web URL" v-model="modal.data.panelUrl" required>
                                </base-input>
                                <small>{{$t('info.https_info_text', {url: 'panel.example.com'})}}</small>
                            </b-col>
                        </template>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col md="3">
                            <label class="form-control-label">{{$t('general.webUrlStatus')}}</label>
                            <b-form-checkbox v-model="modal.data.webUrlStatus" class="switchCheck dblock" name="check-button" switch>
                                {{modal.data.webUrlStatus ? $t('general.active') : $t('general.passive')}}
                            </b-form-checkbox>
                        </b-col>
                        <template v-if="modal.data.webUrlStatus === true">
                            <b-col md="6">
                                <base-input type="text" name="webUrlStatus" nameAs="Web URL" label="Web URL" placeholder="Web URL" v-model="modal.data.webUrl" required>
                                </base-input>
                                <small>{{$t('info.https_info_text', {url: 'randevu.example.com'})}}</small>
                            </b-col>
                        </template>
                    </b-row>
                    <b-row>
                        <b-col md="3">
                            <label class="form-control-label">{{$t('general.privateClinic')}}</label>
                            <b-form-checkbox v-model="modal.data.privateClinic" class="switchCheck dblock" name="check-button" switch>
                                {{modal.data.privateClinic ? $t('general.active') : $t('general.passive')}}
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6">
                            <small>{{$t('info.private_clinic_info_text')}}</small>
                        </b-col>
                    </b-row>
                    <template v-if="modal.data.webUrlStatus">
                        <hr>
                        <b-row>
                            <b-col cols="12">
                                <b-card no-body class="mb-1">
                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                        <b-button block v-b-toggle.clinic_css variant="default">{{$t('general.clinic_page_css')}}</b-button>
                                    </b-card-header>
                                    <b-collapse id="clinic_css" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <codemirror v-model="modal.data.webCss" :options="cmOptions"></codemirror>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </b-col>
                            <b-col cols="12">
                                <b-card no-body class="mb-1">
                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                        <b-button block v-b-toggle.terms variant="default">{{$t('general.clinic_terms')}}</b-button>
                                    </b-card-header>
                                    <b-collapse id="terms" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <b-tabs content-class="mt-3">
                                                <b-tab :active="key==0" :title="lang.toUpperCase()" v-for="(lang,key) in setting.lang" :key="key">
                                                    <quill-editor v-model="modal.data.terms[lang]" :options="editorOption" />
                                                </b-tab>
                                            </b-tabs>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </b-col>
                            <b-col cols="12">
                                <b-card no-body class="mb-1">
                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                        <b-button block v-b-toggle.policy variant="default">{{$t('general.clinic_data_policy')}}</b-button>
                                    </b-card-header>
                                    <b-collapse id="policy" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <b-tabs content-class="mt-3">
                                                <b-tab :active="key==0" :title="lang.toUpperCase()" v-for="(lang,key) in setting.lang" :key="key">
                                                    <quill-editor v-model="modal.data.policy[lang]" :options="editorOption" />
                                                </b-tab>
                                            </b-tabs>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </b-col>
                        </b-row>
                    </template>
                    <hr>
                    <b-alert show variant="warning" v-if="invalid">
                        <strong>{{$t('warning.fill_title')}} : </strong> {{$t('warning.fill')}}
                    </b-alert>
                    <base-button type="primary" native-type="submit" :disabled="modal.loading || invalid">{{$t('save')}}</base-button>
                    <base-button type="link text-danger" class="ml-auto float-right" @click="modal.status = false" :disabled="modal.loading">{{$t('close')}}</base-button>
                </b-form>
            </validation-observer>
        </modal>

        <modal :show.sync="photoModal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="photoModalClose">
            <h6 slot="header" class="modal-title">{{$t('general.image_upload')}}</h6>
            <div class="imgSection text-center" v-loading="photoModal.loading">
                <div class="emptyPhoto" v-if="!photoModal.data.logo">
                    <i class="fas fa-building"></i>
                </div>
                <div class="profilPhoto" v-else>
                    <img :src="imgUrl+photoModal.data.logo" alt="">
                </div>
                <b-form-file accept="image/jpeg, image/png" size="sm" v-model="photoModal.data.upload" :placeholder="$t('general.select_img')" :drop-placeholder="$t('general.drop_file')" :browse-text="$t('general.choose')" ref="file" @change="handleFileUpload"></b-form-file>
                <p><br>
                    <base-button type="primary" size="sm" v-if="photoModal.data.upload" @click="uploadPhoto">{{$t('general.upload')}}</base-button>
                </p>
            </div>
            <base-button type="danger" size="sm" class="ml-auto float-right" @click="photoModalClose">{{$t('general.close')}}</base-button>
        </modal>
    </div>
</template>

<script>
import getSlug from 'speakingurl';
import _ from 'lodash';
//import moment from 'moment';
import { mapGetters } from 'vuex';
import { CLINIC_REQUEST, CLINIC_UPDATE, CLINIC_ADD, CLINIC_DELETE } from '@/store/modules/define';
import { handleError } from '@/utils/func';
import { setTimeout } from 'timers';

import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/css/css.js'

let self;
let emptyModal = {
    status: true,
    name: '',
    seo: '',
    url: '',
    email: '',
    phone: '',
    address: '',
    location: { lat: 0, lng: 0 },
    country: '6007eee0aaf082368c83d163',
    city: '',
    payTr : {
        status : false,
        id : '',
        key : '',
        salt : ''
    },
    terms: {},
    policy: {},
    panelUrlStatus: false,
    webUrlStatus: false,
    paymentUrlStatus: false,
    panelUrl: '',
    webUrl: '',
    paymentUrl: '',
    webCss: ''
};
export default {
    computed: {
        ...mapGetters({ clinics: 'getClinics', total: 'getClinicTotal', pages: 'getClinicPages', branchList: 'getBranchList', countries: 'getCountry', cities: 'getCity', setting: 'generalSetting' })
    },
    components: {
        codemirror
    },
    data() {
        const mapOptions = this.$root.defaultMapOptions;
        return {
            editorOption: {
                modules: {
                    clipboard: {
                        matchVisual: true
                    },
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                        ['clean'],
                        ['link', 'image']
                    ]
                },
                placeholder: ''
            },
            imgUrl: process.env.VUE_APP_CDNURL + '/clinic/',
            center: mapOptions.center,
            mapOptions: mapOptions,
            modal: {
                loading: false,
                status: false,
                data: _.cloneDeep(emptyModal)
            },
            file: null,
            photoModal: {
                loading: false,
                status: false,
                data: {}
            },
            loading: true,
            status: {
                true: 'success',
                false: 'danger'
            },
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {},
            cmOptions: {
                tabSize: 4,
                mode: 'css',
                lineNumbers: true,
                line: true,
            }
        };
    },
    methods: {
        addPhoto(row) {
            self.photoModal.loading = false;
            self.photoModal.status = true;
            self.photoModal.data = { clinic: row.uuid, upload: null };
            self.photoModal.data['logo'] = row.logo != null || row.logo != '' ? row.logo : false;
        },
        handleFileUpload() {
            self.file = this.$refs.file.files[0];
        },
        uploadPhoto() {
            self.photoModal.loading = true;
            self.$store
                .dispatch('CLINIC_UPLOAD', { file: self.photoModal.data.upload, clinic: self.photoModal.data.clinic })
                .then(resp => {
                    self.photoModal.loading = false;
                    self.photoModal.status = false;
                    self.getClinics();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.photoModal.loading = false;
                    handleError(self, err);
                });
        },
        async removePicture(user, picture, type) {
			const result = await this.$swal({
				title: this.$i18n.t('quest.sure'),
				text: this.$i18n.t('quest.delete_info'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#f5365c',
				confirmButtonText: this.$i18n.t('quest.photoModal'),
				cancelButtonText: this.$i18n.t('quest.cancel')
			})
			if (result.value) {
				this.loading = true
				try {
					await this.$store.dispatch('DOCTOR_PICTUREDELETE', {user, picture, type})
					this.loading = false
					this.$delete(this.modal.data[type], this.modal.data[type].indexOf(picture))
					this.$notify({type: 'success', message: self.$i18n.t('success.positive')})
				} catch (err) {
					this.loading = false
					handleError(this, err)
				}
			}
		},
        photoModalClose() {
            self.photoModal.loading = false;
            self.photoModal.status = false;
            self.photoModal.data = {};
        },
        tableRowClassName({ row, rowIndex }) {
            if(!row.status){
                return 'table-danger';
            }
            return '';
        },
        generateSeo(key) {
            let lang = self.$i18n.locale;
            self.modal.data.seo = getSlug(self.modal.data[key], { lang });
        },
        changeCity(value) {
            let city = _.get(
                _.filter(self.cities, item => {
                    return item._id == value;
                }),
                0
            );

            let marker = { lat: parseFloat(city.latitude), lng: parseFloat(city.longitude) };
            _.set(self.modal.data, 'location', marker);
            self.center = _.cloneDeep(marker);
        },
        markerDrag(location) {
            let marker = { lat: location.latLng.lat(), lng: location.latLng.lng() };
            _.set(self.modal.data, 'location', marker);
            self.center = _.cloneDeep(marker);
        },
        changePage(val) {
            self.loading = true;
            self.pagination.page = val;
            self.getClinics();
        },
        handleEdit(row) {
            self.modal.loading = false;
            self.modal.status = true;
            self.modal.data = _.cloneDeep(emptyModal);
            self.modal.data = _.merge(self.modal.data, row);
            if (self.modal.data.location.lat != 0) {
                setTimeout(() => {
                    self.center = _.cloneDeep(self.modal.data.location);
                }, 500);
            }
        },
        handleDelete(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(CLINIC_DELETE, uuid)
                        .then(resp => {
                            self.loading = false;
                            self.getClinics();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        modalClose() {
            self.modal.loading = false;
            self.modal.status = false;
            self.modal.data = _.cloneDeep(emptyModal);
        },
        addNew() {
            self.modal.loading = false;
            self.modal.status = true;
            self.modal.data = _.cloneDeep(emptyModal);
        },
        onSubmit() {
            if (self.modal.data.city != '' && self.modal.data.location.lat == 0) {
                self.$notify({ type: 'warning', message: self.$i18n.t('warning.no_location') });

                return false;
            }

            if (self.modal.data.city == '') {
                self.$notify({ type: 'warning', message: self.$i18n.t('warning.no_city') });

                return false;
            }

            if (self.modal.data.uuid != undefined) {
                self.onUpdate();
            } else {
                self.onAdd();
            }
        },
        onAdd() {
            let clinic = _.pickBy(self.modal.data, (item, key) => {
                switch (typeof item) {
                    case 'boolean':
                        return true;
                        break;
                    case 'string':
                        return item.trim() != '';
                        break;
                    default:
                        return true;
                        break;
                }
            });

            self.modal.loading = true;
            self.$store
                .dispatch(CLINIC_ADD, clinic)
                .then(resp => {
                    self.modalClose();
                    self.getClinics();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        onUpdate() {
            let uuid = self.modal.data.uuid;
            let clinic = _.pickBy(self.modal.data, (item, key) => {
                if (key == 'uuid') {
                    return false;
                }

                switch (typeof item) {
                    case 'boolean':
                        return true;
                        break;
                    case 'string':
                        return item.trim() != '';
                        break;
                    default:
                        return true;
                        break;
                }
            });

            self.modal.loading = true;
            self.$store
                .dispatch(CLINIC_UPDATE, { uuid, clinic })
                .then(resp => {
                    self.modal.loading = false;
                    self.getClinics();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        getClinics() {
            self.loading = true;
            self.$store
                .dispatch(CLINIC_REQUEST, { pagination: self.pagination, filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        }
    },
    filters: {
        showCountry(value) {
            let country = _.get(
                _.filter(self.countries, item => {
                    return item._id == value;
                }),
                0
            );

            return country ? country.name : '';
        },
        showCity(value) {
            let city = _.get(
                _.filter(self.cities, item => {
                    return item._id == value;
                }),
                0
            );

            return city ? city.name : '';
        },
        showBranch(value) {
            let lang = self.$i18n.locale;
            let branches = _.map(
                _.pickBy(self.branchList, item => {
                    return item.status && _.includes(value, item._id);
                }),
                item => {
                    return item.name[lang];
                }
            );

            return _.join(branches, '<br/> ');
        }
    },
    created() {
        self = this;
        self.getClinics();
    }
};
</script>
